import styled from '@emotion/styled';

export const Heading = styled.h1`
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 1px;
    color: var(--blue);
    margin: 0;
`;

export const Subheading = styled.h5`
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    color: var(--blue);
    margin: 0;
`;

export const Paragraph = styled.p`
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 1px;
    color: var(--black);
    margin: 0;
`;

const baseUrl = process.env.REACT_APP_API_URL;

export const getData = async <R>(url: string): Promise<R> => {
  const response = await fetch(`${baseUrl}${url}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });
  if (response.ok) {
    return response.json();
  }
  throw response;
}

export const putData = async <D>(url: string, data: D): Promise<void> => {
  const response = await fetch(`${baseUrl}${url}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });
  if (response.ok) {
    return;
  }
  throw response;
}

import React, { FC } from 'react';
import { motion } from 'framer-motion';

import { Heading, Paragraph } from 'styles';
import { WelcomeSvg, FacilityName, Button } from './Welcome.styled';

export const Welcome: FC<{ facilityName: string, onStart(): void }> = ({ facilityName, onStart }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
  >
    <WelcomeSvg />
    <Heading style={{ marginBottom: '34px' }}>
      Welcome!
    </Heading>
    <Paragraph>
      <FacilityName>{facilityName}</FacilityName>
      {' '}
      would like you to answer a few questions.  Please press Start to continue.
    </Paragraph>
    <Button
      type="button"
      onClick={onStart}
    >
      Start
    </Button>
  </motion.div>
);

import styled from '@emotion/styled';

import { ReactComponent as Arrow } from 'images/arrow.svg';

export const QuestionText = styled.h2`
    margin-bottom: 60px;
    margin-top: 40px;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 1px;
    color: var(--blue);
`;

export const ChoiceButton = styled.button<{ selected: boolean }>`
    border: 2px solid var(--violet);
    width: 301px;
    height: 60px;
    max-width: 100%;
    border-radius: 9px;
    margin-bottom: 24px;
    background: var(${({ selected }) => (selected ? '--violet' : '--white')});
    color: var(${({ selected }) => (selected ? '--white' : '--violet')});
`;

export const ArrowSvg = styled(Arrow)`
    width: 18px;
    height: 24px;
    padding: 5px;
    margin-left: -5px;
    position: absolute;
    left: 0;

    path {
        color: var(--black);
    }
`;

export const ProgressLabel = styled.label`
    line-height: 24px;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 1px;
    color: var(--gray);
    display: block;
    
    span {
        color: var(--black);
    }
`;

export const ProgressHeader = styled.div`
    position: relative;
    margin-bottom: 33px;
    margin-top: 38px;
`;

export const Numpad = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 30px;
`;

export const NumpadCell = styled.div<{ fullSpan: boolean }>`
    ${({ fullSpan }) => (fullSpan ? 'grid-column: 2;' : '')}
`;

export const NumCircle = styled.div<{ selected: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    margin: 0 auto;
    font-weight: 700;
    font-size: 30px;
    letter-spacing: 1px;
    border-radius: 50%;
    color: var(${({ selected }) => (selected ? '--violet' : '--gray')});
    border: 2px solid ${({ selected }) => (selected ? 'var(--violet)' : 'transparent')};
`;

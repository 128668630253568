import styled from '@emotion/styled';

import { ReactComponent as ThankYou } from 'images/thank-you.svg';

export const ThankYouSvg = styled(ThankYou)`
    margin-top: 13vh;
    margin-bottom: 27px;
    width: 262px;
    max-width: 100%;
`;

import React, { FC, useRef } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import { motion, AnimatePresence } from 'framer-motion';

import { SurveyPage, SurveyPageElementType, SurveyPageElement } from 'types';
import {
  QuestionText, ChoiceButton, ArrowSvg, ProgressLabel, ProgressHeader, Numpad, NumpadCell, NumCircle,
} from './Question.styled';

interface IQuestionProps {
  data: SurveyPage
  step: number
  totalSteps: number
  goNextPageAutomatic: boolean
  values: Record<string, string|number>
  onChange: (contentId: string, value: string|number) => void
  onNext(): void
  onBack(): void
}

const pageAnimations = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: ([isBackRef, step]: any[]) => ((isBackRef?.current && step === 1) ? {} : ({ opacity: 0 })),
}

const slideAnimations = {
  initial: (isBackRef: any) => (isBackRef?.current
    ? ({ opacity: 0, x: -100 })
    : ({ opacity: 0, x: 100 })),
  animate: { opacity: 1, x: 0 },
  exit: (isBackRef: any) => (isBackRef?.current
    ? ({ opacity: 0, x: 100 })
    : ({ opacity: 0, x: -100 })),
}

export const Question: FC<IQuestionProps> = ({
  data, onChange, step, totalSteps, goNextPageAutomatic, values, onNext, onBack,
}) => {
  const isBackRef = useRef<boolean>(false);

  const renderChoices = ({ choices, type, name }: SurveyPageElement) => {
    if (type === SurveyPageElementType.NUMPAD) {
      return (
        <Numpad>
          {[...new Array(10)].map((_, index) => (
            <NumpadCell
              key={String(index)}
              fullSpan={index === 9}
              onClick={() => {
                isBackRef.current = false;
                onChange(name, index + 1);
              }}
            >
              <NumCircle selected={values[name] === index + 1}>
                {index + 1}
              </NumCircle>
            </NumpadCell>
          ))}
        </Numpad>
      );
    }

    return choices.map((value) => (
      <ChoiceButton
        key={value}
        selected={values[name] === value}
        type="button"
        onClick={() => {
          isBackRef.current = false;
          onChange(name, value);
        }}
      >
        {value}
      </ChoiceButton>
    ))
  }

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageAnimations}
      custom={[isBackRef, step]}
    >
      <ProgressHeader>
        <ArrowSvg
          onClick={() => {
            isBackRef.current = true;
            onBack()
          }}
        />
        <ProgressLabel>
          <span>{step}</span>
          {' '}
          of
          {' '}
          {totalSteps}
        </ProgressLabel>
      </ProgressHeader>
      <ProgressBar
        completed={step * 100 / totalSteps}
        bgColor="var(--violet)"
        baseBgColor="var(--secondary-gray)"
        borderRadius="0"
        height="2px"
        isLabelVisible={false}
      />

      <AnimatePresence initial={false} exitBeforeEnter>
        <motion.div
          variants={slideAnimations}
          animate="animate"
          initial="initial"
          exit="exit"
          key={step}
          custom={isBackRef}
          transition={{
            x: {
              ease: 'linear',
            },
            duration: 0.4,
          }}
        >
          {data.elements.map((element) => (
            <section style={{ marginBottom: '20px' }} key={element.name}>
              <QuestionText>
                {element.title}
              </QuestionText>
              {renderChoices(element)}
            </section>
          ))}
        </motion.div>
      </AnimatePresence>
    </motion.div>
  )
}

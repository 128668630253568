import styled from '@emotion/styled';

import { ReactComponent as Welcome } from 'images/welcome.svg';

export const WelcomeSvg = styled(Welcome)`
    width: 100%;
    margin-bottom: 27px;
    margin-top: 8vh;
`;

export const FacilityName = styled.span`
    text-transform: uppercase;
`;

export const Button = styled.button`
    width: 100%;
    height: 60px;
    max-width: 301px;
    border-radius: 9px;
    color: var(--white);
    background-color: var(--blue);
    font-size: 16px;
    line-height: 30px;
    margin-top: 12vh;
    border: none;
`;

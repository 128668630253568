export enum SurveyPageElementType {
  RADIOGROUP = 'radiogroup',
  NUMPAD = 'numpad'
}

export interface SurveyPageElement {
  choices: string[]
  isRequired: boolean
  name: string
  rateMax: number
  rateMin: number
  title: string
  type: SurveyPageElementType
}

export interface SurveyPage {
  elements: SurveyPageElement[]
  name: string
}

export interface SurveyPagesResponse {
  goNextPageAutomatic: false
  navigateToUrl: string
  pages: SurveyPage[]
  sendResultOnPageNext: boolean
  showCompletedPage: boolean
  farewell: string
}

export type SurveyResponsesResponse = undefined | null | {
  IsSurveyComplete: boolean
  Responses: SurveyResponse[]
}

export interface SurveyResponse {
  ContentId: number
  ResponseId: number
  SurveyResponse: string|number
}

export interface SurveyResponsesRequest {
  IsSurveyComplete: boolean
  Responses: {
    ContentId: string
    SurveyResponse: string|number
  }[]
}

import React, { FC } from 'react';
import { motion } from 'framer-motion';

import { Subheading } from 'styles';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';
import { ThankYouSvg } from './ThankYou.styled';

export const ThankYou: FC<{
  facilityNumber: string
  farewell: string | undefined
}> = ({ facilityNumber, farewell }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
  >
    <ThankYouSvg />
    <Subheading style={{ marginBottom: '24px' }}>
      {farewell}
    </Subheading>
    <Subheading style={{ marginBottom: '24px' }}>
      If you have questions please dial
      {' '}
      {formatPhoneNumber(facilityNumber)}
    </Subheading>
  </motion.div>
)

import React from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

import { formatPhoneNumber } from 'utils/formatPhoneNumber';
import { Heading, Paragraph } from 'styles';
import { AlreadyCompletedSvg } from './SurveyAlreadyCompleted.styled';

export const SurveyAlreadyCompleted: React.FC = () => {
  const { forwardNumber } = useParams();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: 'easeIn' }}
    >
      <AlreadyCompletedSvg />
      <Heading style={{ marginBottom: '16px' }}>
        It looks like you completed this survey!
      </Heading>
      <Paragraph>
        If you have questions please dial
        {' '}
        <b>{formatPhoneNumber(forwardNumber)}</b>
      </Paragraph>
    </motion.div>
  )
}

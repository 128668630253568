import React, {
  FC, useEffect, useState, useMemo, useRef,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { getData, putData } from 'utils/api';
import logger from 'lib/logging';
import { Welcome } from 'components/Welcome';
import { ThankYou } from 'components/ThankYou';
import { Question } from 'components/Question';
import { SurveyResponsesResponse, SurveyPagesResponse, SurveyResponsesRequest } from 'types';

export const Survey: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Record<string, any>>();
  const [facilityName, setFacilityName] = useState<string>('');
  const [facilityNumber, setFacilityNumber] = useState<string>('');
  const { callId } = useParams();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [values, setValues] = useState<Record<string, string|number>>({});
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [farewell, setFarewell] = useState<string>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const pagesData = await getData<SurveyPagesResponse>(
          `api/v2/SurveyEvent/${callId}/Pages`,
        );
        const responsesData = await getData<SurveyResponsesResponse>(
          `api/v2/SurveyEvent/${callId}/Response`,
        );

        if (!pagesData.navigateToUrl) {
          logger.error('navigateToUrl not found in response ', pagesData);
          navigate('/error');
          return;
        }

        const forwardNumber = pagesData.navigateToUrl.substring(pagesData.navigateToUrl.lastIndexOf('/') + 1);
        setFacilityNumber(forwardNumber);
        setFacilityName(pagesData.pages[0].name);
        setFarewell(pagesData.farewell);

        if (responsesData) {
          if (responsesData.IsSurveyComplete === true) {
            navigate(`/surveyCompletion/${forwardNumber}`);
            return;
          }

          const initialValues = responsesData?.Responses?.reduce((acc, response) => ({
            ...acc,
            [response.ContentId]: response.SurveyResponse,
          }), {});

          setValues(initialValues);
        }

        setData(pagesData);
        setIsLoading(false);
      } catch (response: any) {
        logger.error(response);
        navigate('/error');
      }
    })();
  }, [callId, navigate, setValues]);

  const StepJSX = useMemo(() => {
    if (!data) {
      return null;
    }

    if (currentStep === 1) {
      return (
        <Welcome
          key="welcome"
          facilityName={facilityName}
          onStart={() => {
            setCurrentStep(currentStep + 1);
          }}
        />
      );
    }

    if (currentStep === data.pages.length + 2) {
      return (
        <ThankYou
          facilityNumber={facilityNumber}
          key="thankYou"
          farewell={farewell}
        />
      );
    }

    const saveSurveyResponses = (submitValues = values) => {
      const isLastStep = currentStep === data.pages.length + 1;

      const putSurveyResponsesBody = {
        IsSurveyComplete: isLastStep,
        Responses: Object.entries(submitValues).map(([ContentId, SurveyResponse]) => ({
          ContentId,
          SurveyResponse: `${SurveyResponse}`,
        })),
      }

      try {
        putData<SurveyResponsesRequest>(
          `api/v2/SurveyEvent/${callId}/Response`,
          putSurveyResponsesBody,
        );
      } catch (response) {
        logger.error(response)
        navigate('/error');
      }
    }

    const goNextPageAutomatic = true;

    return (
      <Question
        key="question"
        data={data.pages[currentStep - 2]}
        step={currentStep - 1}
        totalSteps={data.pages.length}
        onChange={(contentId: string, value: string|number) => {
          const newValues = {
            ...values,
            [contentId]: value,
          }
          setValues(newValues);

          if (goNextPageAutomatic) {
            saveSurveyResponses(newValues);
            timeoutRef.current = setTimeout(() => {
              setCurrentStep(currentStep + 1);
            }, 800);
          }
        }}
        goNextPageAutomatic={goNextPageAutomatic}
        values={values}
        onNext={() => {
          setCurrentStep(currentStep + 1);
          saveSurveyResponses();
        }}
        onBack={() => {
          if (timeoutRef?.current) {
            clearTimeout(timeoutRef.current);
          }
          setCurrentStep(currentStep - 1);
        }}
      />
    );
  }, [callId, currentStep, data, facilityName, facilityNumber, navigate, setValues, values]);

  useEffect(() => () => {
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {StepJSX}
    </AnimatePresence>
  )
}

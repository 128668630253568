import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import { SurveyAlreadyCompleted } from 'pages/SurveyAlreadyCompleted';
import { Survey } from 'pages/Survey';
import { Container } from 'styles';

const App: React.FC = () => (
  <Container>
    <Router>
      <Routes>
        <Route path="/error" element={<div>Something went wrong</div>} />
        <Route path="/surveyCompletion/:forwardNumber" element={<SurveyAlreadyCompleted />} />
        <Route path="/:callId" element={<Survey />} />
        <Route path="/" element={<div>this page intentionally left blank</div>} />
      </Routes>
    </Router>
  </Container>
)

export default App;
